.marketing__header {
  position: fixed;
  width: 100%;
  background: var(--brand-primary-dark);
  color: #fff;
  z-index: 9;
  font-weight: 500;
  height: calc(125px * var(--height-increment));
}

.marketing__header > div {
  display: grid;
  grid-template-columns: 200px auto;
  align-items: center;
  /* padding: 0 !important; */
}

.marketing__header_logo {
  cursor: pointer;
  margin: calc(var(--height-increment) * 32px)
    calc(var(--width-increment) * 32px);
  display: block;
}

.marketing__header_nav {
  text-align: right;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-basis: 0;
}

.marketing__header_nav_item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(var(--width-increment) * 198px);
  border-left: 1px solid #fff;
  cursor: pointer;
  font-size: 1.2rem;
  white-space: nowrap;
  min-width: max-content;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.marketing__header_nav
  .marketing__header_nav_item.marketing__header_nav_item_blue {
  background: #00325a;
}

.marketing__header_nav_item svg {
  float: left;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.marketing__header_nav_icon {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .marketing__header_nav_item {
    width: calc(var(--width-increment) * 150px);
  }
}

@media screen and (max-width: 980px) {
  .marketing__header {
    padding: 1.5rem 1rem 1rem 0;
    height: auto;
    z-index: 10;
  }

  .marketing__header_logo {
    height: 40px;
    margin: 0;
  }

  .marketing__header > div {
    display: block;
    grid-template-columns: none;
  }

  .marketing__header_nav {
    text-align: center;
    position: fixed;
    display: block;
    top: 80px;
    right: -100%;
    width: 100%;
    z-index: 9;
    background: var(--brand-primary-dark);
    transition: right ease-in-out 0.25s;
  }

  .marketing__header_nav_active {
    right: 0;
  }

  .marketing__header_nav_item {
    display: block;
    border: 0;
    height: auto;
    width: 100%;
  }

  .marketing__header_nav_item svg {
    display: none;
  }

  .marketing__header_nav_icon {
    display: block;
  }
}
