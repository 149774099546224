.marketing__content {
  position: relative;
  padding: var(--section-padding-normal);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.marketing__content > div[class*="layout__container"] {
  max-width: 1264px;
  padding: 0;
}

.marketing__content * {
  position: relative;
  z-index: 2;
}

.marketing__content_outerimage {
  position: absolute;
  bottom: 0;
  right: 19.5vw;
  height: 600px;
}

.marketing__content_innerimage {
  display: block;
  height: 400px;
  float: right;
}

.marketing__content_backgroundimage {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center right;
  z-index: 0;
}

.marketing__content_hasminheight {
  position: relative;
  display: flex;
  align-items: center;
}

.marketing__content_centered {
  text-align: center;
}

.marketing__content_narrow {
  max-width: 1100px;
  margin: 0 auto;
}

.marketing__content_fullwidth h2 {
  text-align: center;
}

.marketing__content_fullwidth .marketing__content_columns {
  display: block;
}

.marketing__content_fullwidth .marketing__content_column_content {
  max-width: 1264px;
}

.marketing__content_columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  align-items: center;
}

.marketing__content_column_content p:last-of-type {
  margin-bottom: 0;
}

/* Blue Variant */

.marketing__content_blue {
  background-color: #00325a;
  color: #fff;
}

.marketing__content_blue h2 {
  color: #fff !important;
}

.marketing__content_blue p {
  color: #fff !important;
}

/* Grey Variant */

.marketing__content_grey {
  background-color: #bfcbd5;
}

.marketing__content_grey h2 {
  color: var(--brand-secondary) !important;
}

.marketing__content_green {
  background-color: var(--brand-primary);
}

.marketing__content_green,
.marketing__content_green p,
.marketing__content_green h2 {
  color: white !important;
}

/* Light Grey Variant */

.marketing__content_lightgrey {
  background-color: #f8f8f8;
}

/* Right-aligned */

.marketing__content_right {
  background-position: left;
}

.marketing__content_right .marketing__content_columns > div:nth-of-type(1) {
  grid-row: 1;
  grid-column: 2;
}

.marketing__content_right .marketing__content_columns > div:nth-of-type(2) {
  grid-row: 1;
  grid-column: 1;
}

.marketing__content_right .marketing__content_backgroundimage {
  right: auto;
  left: 0;
  background-position: center left;
}

.marketing__content_watermark {
  position: absolute;
  top: 0;
  left: 0;
}

.marketing__content_column_content {
  padding: 0 1rem;
}

@media screen and (max-width: 1280px) {
  .marketing__content_outerimage {
    bottom: 0;
    right: 25%;
    height: 400px;
  }
}

@media screen and (max-width: 980px) {
  .splitSection {
    padding-top: calc(70px * var(--height-increment)) !important;
    background-color: #e8f5f7;
  }

  .marketing__content_columns {
    display: block;
    grid-template-columns: none;
  }

  .marketing__content_column_content {
    max-width: none;
    padding: 0 1rem;
  }

  .marketing__content_outerimage {
    display: none;
  }

  .marketing__content_backgroundimage {
    width: 100%;
    opacity: 0.5;
  }

  .marketing__content_hasminheight {
    min-height: 0 !important;
  }
}

.wideTitle {
  max-width: 420px !important;
}