.icons__socialicons {
  margin-top: 2rem;
  margin-bottom: 7.5rem;
  z-index: 1;
}

.icons__socialicons > a,
.icons__socialicons > svg {
  margin-right: 0.5rem;
}

@media screen and (max-width: 980px) {
  .icons__socialicons {
    visibility: hidden;
  }
}
