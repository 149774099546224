.marketing__columns {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
}

.marketing__columns_inner {
  position: relative;
  overflow: hidden;
}

.marketing__columns_inner * {
  position: relative;
  z-index: 1;
}

.marketing__columns_inner p:last-of-type {
  margin-bottom: 0;
}

.marketing__columns_inner p:last-of-type {
  margin-bottom: 0;
}

.marketing__columns_inner_overlay {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff !important;
}

.marketing__columns_inner_overlay * {
  color: inherit !important;
}

.marketing__columns_inner_center_wide h2,
.marketing__columns_inner_overlay h2 {
  font-size: 2.5rem !important;
}

.marketing__columns_inner_overlay p {
  font-size: 2rem !important;
}

.marketing__columns_inner_overlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00325a;
  opacity: 0.9;
  z-index: 0;
}

.marketing__columns_inner_center h2 {
  display: inline-block;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 1.5rem !important;
  border-bottom: 2px solid #fff;
  width: 276px;
  padding: 0 0 1.25rem;
}

.marketing__columns_inner_center p {
  color: var(--brand-primary) !important;
}

.marketing__columns_inner_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marketing__columns_inner_center_wide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 530px;
  width: 90%;
  text-align: center;
}

.splitSection {
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
}

.splitSection.number {
  font-size: 3rem;
  font-weight: normal;
  background: var(--brand-secondary);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  margin: auto;
}

.splitSections {
  row-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100px 1fr;
  grid-auto-flow: column;
}

@media screen and (max-width: 980px) {
  .marketing__columns:not(.marketing__columns_matchmobile) {
    grid-template-columns: 1fr !important;
  }

  .marketing__columns_matchmobile {
    margin-bottom: 2rem;
    text-align: center;
  }

  .marketing__columns_inner_center_wide h2 {
    padding-top: 5rem;
  }

  .marketing__columns_inner_center_wide p:last-of-type {
    font-size: calc(var(--font-size-base) * 2) !important;
  }

  .marketing__columns_inner_center_wide svg {
    display: none;
  }

  .splitSections {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100px 1fr 100px 1fr;
  }
}
