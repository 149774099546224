.layout__container {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
  margin: 0 auto;
}

.layout__container_100 {
  padding-right: 0;
}

.layout__container_780 {
  max-width: 780px;
}

.layout__container_1100 {
  max-width: 1100px;
  padding: 0;
}

.layout__container_1264 {
  max-width: 1264px;
}

.layout__container * {
  position: relative;
  z-index: 1;
}

.layout__container_watermark {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-25%, -25%);
  z-index: 0;
  opacity: 50%;
}

@media (max-width: 1400px) {
  .layout__container,
  .layout__container_780,
  .layout__container_1100,
  .layout__container_1264 {
    max-width: none;
    padding: 0 1rem;
    width: 100%;
  }
}
