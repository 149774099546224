.marketing__footer .marketing__footer_primary {
  padding: var(--section-padding-small);
  color: var(--brand-primary);
  line-height: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.marketing__footer .marketing__footer_secondary {
  padding: 1.5rem 0;
  background: var(--brand-secondary);
  color: #fff;
  font-size: 1.25rem;
}

.marketing__footer .marketing__footer_primary a {
  line-height: 49px;
}

.marketing__footer_text {
  float: right;
  line-height: 49px;
  margin-right: 1.25rem;
}

.marketing__footer .marketing__footer_primary a,
.marketing__footer .marketing__footer_secondary a {
  margin-right: 1.5rem;
}

.marketing__footer_icon {
  margin-right: 0.5rem;
  float: right;
}

.marketing__footer_icon:first-of-type {
  margin-right: 0;
}

.marketing__footer_right {
  text-align: right;
}

.marketing__footer_inner {
  max-width: calc(1645px * var(--width-increment));
  padding: 0 1.5rem;
  margin: 0 auto;
}

.marketing__footer_inner_sub {
  font-size: 1rem;
}

@media screen and (max-width: 980px) {
  .marketing__footer .marketing__footer_primary {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .marketing__footer .marketing__footer_primary a {
    display: inline-block;
    width: 50%;
    margin-right: 0;
    text-align: center;
  }

  .marketing__footer .marketing__footer_right {
    padding: var(--section-padding-small) 0 0;
    text-align: center;
  }
  .marketing__footer .marketing__footer_right a {
    width: auto;
  }

  .marketing__footer .marketing__footer_right span {
    display: none;
  }

  .marketing__footer .marketing__footer_right svg {
    float: none;
    margin-right: 0.5rem;
    transform: scale(0.75);
  }

  .marketing__footer .marketing__footer_secondary {
    font-size: 0.75rem;
    text-align: center;
  }
}

.subFooter {
  font-size: 0.8rem;
}

.subFooter a {
  line-height: 24px;
}
